export function TheGrid() {
  return (
    <div className='absolute top-0 inset-x-0 overflow-hidden pointer-events-none'>
      <img
        src='/landing-grid.svg'
        className='relative left-1/2 -translate-x-1/2 will-change-transform max-w-none'
      />
    </div>
  )
}
