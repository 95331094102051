import { useRouter } from 'next/router'
import { InputForm } from 'components/marketing/InputForm'
import { useUserContext } from 'components/UserProvider'
import { Button } from 'components/marketing/v2/Button'

export function WaitingFor() {
  const { user } = useUserContext()
  const router = useRouter()

  const inputFormData = {
    placeholder: 'Your API endpoint...',
    buttonText: 'Get started',
    customOnSubmit: (url) => {
      router.push({
        pathname: '/signup',
        query: {
          redirect: `/app/onboarding?origin=${encodeURIComponent(url)}`,
        },
      })
    },
  }

  return (
    <section className='max-w-7xl px-6 mx-auto'>
      <div className='py-16 grid gap-4 lg:grid-cols-2 lg:gap-8 lg:items-end lg:py-20'>
        <div className='space-y-8'>
          <div className='text-14 uppercase font-demibold tracking-wider leading-tight text-neutral-60'>
            SEE FOR YOURSELF
          </div>
          <h4 className='text-32 leading-1.1 font-demibold lg:text-38'>
            What are you waiting for?
          </h4>
          <p className='text-20 font-medium'>
            It takes minutes to set up our platform for your GraphQL API to see
            where you can scale and start making optimizations.
          </p>
          {user ? (
            <Button href='/app'>Go to dashboard</Button>
          ) : (
            <div className='max-w-120'>
              <InputForm {...inputFormData} theme='light' />
            </div>
          )}
        </div>
        <div>
          <img src='/features/caching-bars.svg' />
        </div>
      </div>
    </section>
  )
}
