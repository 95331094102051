import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
} from 'react'
import cx from 'classnames'

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode
  secondary?: boolean
  destructive?: boolean
  full?: boolean
  pageType?: 'marketing' | 'product'
  hasArrow?: boolean
  small?: boolean
  color?: 'blue' | 'red' | 'magenta' | 'white' | 'none'
  loading?: boolean
  tabIndex?: number
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className = '',
      small,
      full,
      secondary,
      hasArrow,
      color = 'none',
      loading,
      pageType = 'product',
      destructive,
      children,
      tabIndex = 0,
      disabled,
      ...rest
    },
    ref,
  ) => {
    return (
      <button
        tabIndex={tabIndex}
        ref={ref}
        className={cx(
          `text-center whitespace-nowrap flex items-center ${
            pageType === 'marketing'
              ? 'font-demibold leading-none focus:outline-blue w-fit-content'
              : 'font-medium focus:ring focus:outline-none sm:w-auto'
          } rounded-6 transition sm:flex-shrink-0 sm:inline-flex sm:items-center`,
          {
            'bg-neutral-10 hover:bg-neutral-15 text-neutral ring-neutral':
              secondary,
            'bg-blue text-white hover:opacity-85 svg-white':
              !secondary &&
              !destructive &&
              color !== 'white' &&
              pageType === 'product',
            'w-full flex items-center justify-center': full,
            'text-lg py-3.5 px-4.25 leading-1.3':
              !small && pageType === 'product',
            'py-3.25 px-3.75 text-18': !small && pageType === 'marketing',
            'px-2.5 py-1.5 text-base leading-1.5':
              small && pageType === 'product',
            'px-2.5 py-1.5 small-body-text': small && pageType === 'marketing',
            'cursor-not-allowed pointer-events-none': loading,
            'cursor-not-allowed pointer-events-none opacity-75': disabled,
            'bg-blue text-white hover:opacity-85 ring-blue svg-white':
              color === 'blue' && pageType === 'product',
            'bg-blue text-white hover:bg-blue-110 svg-white':
              color === 'blue' && pageType === 'marketing',
            'bg-magenta text-white hover:opacity-85 ring-magenta svg-white':
              color === 'magenta' && pageType === 'product',
            'bg-magenta text-white hover:bg-magenta-110 svg-white':
              color === 'magenta' && pageType === 'marketing',
            'bg-red text-white hover:opacity-85 ring-red svg-white':
              (destructive || color === 'red') && pageType === 'product',
            'bg-red text-white hover:bg-red-110 svg-white':
              (destructive || color === 'red') && pageType === 'marketing',
            'bg-white hover:opacity-85 text-blue ring-white svg-blue':
              color === 'white' && pageType === 'product',
            'bg-white hover:bg-opacity-85 text-blue-110 ring-white svg-blue':
              color === 'white' && pageType === 'marketing',
          },
          className,
        )}
        disabled={disabled}
        {...rest}
      >
        {children}
        {hasArrow && <Arrow className='h-3 w-auto flex-shrink-0 pl-2.5' />}
      </button>
    )
  },
)

Button.displayName = 'Button'

const Arrow = ({ ...props }: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='9'
    height='14'
    fill='none'
    viewBox='0 0 9 14'
    {...props}
  >
    <path stroke='currentColor' strokeWidth='2' d='M1 12.94l6-5.97L1 1' />
  </svg>
)
