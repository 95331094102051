import React, { useState } from 'react'
import { Button } from 'designsystem/Button'
import { Input } from 'designsystem/Input'
import { useForm } from 'react-hook-form'

type FormData = {
  url: string
}

type SchemaStatus = 'idle' | 'invalid-url'

interface InputFormProps extends React.HTMLAttributes<HTMLFormElement> {
  theme?: 'light' | 'dark'
  customOnSubmit: (url: string) => void
  placeholder: string
  buttonText: string
}

export function InputForm({
  theme = 'light',
  customOnSubmit,
  placeholder,
  buttonText,
  className,
  ...props
}: InputFormProps): JSX.Element {
  const [schemaStatus, setSchemaStatus] = useState<SchemaStatus>('idle')

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: { url: '' },
  })

  const validateUrl = (url: string): boolean => {
    try {
      new URL(url)
      return true
    } catch (err) {
      setSchemaStatus('invalid-url')
      return false
    }
  }

  const handleOnSubmit = handleSubmit((formData) => {
    // reset state on submit
    setSchemaStatus('idle')

    const urlWithHttp =
      formData.url.startsWith('http://') || formData.url.startsWith('https://')
        ? formData.url
        : `http://${formData.url}`

    // check for valid url
    const isUrlValid = validateUrl(urlWithHttp)

    if (isUrlValid) {
      customOnSubmit(urlWithHttp)
    }
  })

  const urlWarning =
    {
      'invalid-url': <>This is not a valid URL.</>,
    }[schemaStatus] ?? null

  return (
    <form
      onSubmit={handleOnSubmit}
      className={`max-w-560 mx-auto flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 justify-center ${
        className || ''
      }`}
      {...props}
    >
      <div className='flex-grow'>
        <Input
          pageType='marketing'
          className='bg-transparent'
          theme={theme}
          placeholder={placeholder}
          warning={urlWarning}
          {...register('url', { required: true })}
        />
      </div>

      <div className='sm:relative'>
        <Button pageType='marketing' color='blue' type='submit'>
          {buttonText}
        </Button>
      </div>
    </form>
  )
}
