import cx from 'classnames'
import LinkArrow from '../../../icons/LinkArrow'

export function TestimonialCard({
  quote,
  authorName,
  authorTitle,
  authorAvatarUrl,
  companyLogo,
  color = 'white',
  size = 'normal',
  caseStudyLink,
}: {
  quote: string
  authorName: string
  authorTitle: string
  authorAvatarUrl?: string
  companyLogo: React.ReactNode
  color: 'white' | 'blue' | 'neutral'
  size?: 'normal' | 'large'
  caseStudyLink?: string
}) {
  return (
    <div
      className={cx('rounded-12 p-8 space-y-4 shadow-md', {
        'bg-white text-neutral': color === 'white',
        'bg-neutral-80 text-white': color === 'neutral',
        'bg-blue text-white': color === 'blue',
        'lg:p-10 lg:space-y-6': size === 'large',
      })}
    >
      <p
        className={cx('text-18 font-medium leading-snug', {
          'lg:text-24': size === 'large',
        })}
      >
        “{quote}”
      </p>
      <div className='flex flex-wrap gap-2.5'>
        {authorAvatarUrl && (
          <img
            loading='lazy'
            src={authorAvatarUrl}
            className={cx('w-10 h-10 rounded-full', {
              'lg:w-12 lg:h-12': size === 'large',
            })}
          />
        )}
        <div className='flex-1'>
          <p
            className={cx('text-15 font-demibold leading-tight', {
              'lg:text-18': size === 'large',
            })}
          >
            {authorName}
          </p>
          <p
            className={cx('text-12 leading-tight', {
              'lg:text-15': size === 'large',
            })}
          >
            {authorTitle}
          </p>
          {caseStudyLink && (
            <a
              href={caseStudyLink}
              className='inline-flex items-center gap-2 text-18 font-demibold text-blue-110'
              target='_blank'
              rel='noreferrer'
            >
              Check Case Study
              <LinkArrow className='h-2.5 w-auto flex-shrink-0 stroke-current' />
            </a>
          )}
        </div>
        <div>{companyLogo}</div>
      </div>
    </div>
  )
}
