import React from 'react'
import { DetailedHTMLProps, InputHTMLAttributes } from 'react'

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: React.ReactElement | string
  help?: React.ReactElement | string
  error?: React.ReactElement | string
  warning?: React.ReactElement | string
  full?: boolean
  theme?: 'light' | 'dark'
  pageType?: 'marketing' | 'product'
}

export const Input = React.forwardRef(
  (
    {
      label,
      help,
      error,
      children,
      className,
      warning,
      full,
      theme = 'light',
      pageType = 'product',
      ...rest
    }: Props,
    ref,
  ): JSX.Element => {
    return (
      <Label
        pageType={pageType}
        className={`relative group ${full ? 'w-full' : ''}`}
      >
        <span
          className={`${
            pageType === 'marketing'
              ? `absolute small-body-text leading-none left-4 top-0 text-left text-blue ${
                  theme === 'light' ? 'bg-white' : 'bg-neutral'
                } ${label ? 'p-1' : ''} transform -translate-y-1/2`
              : ''
          }`}
        >
          {label}
        </span>

        <div
          className={`${
            label ? 'mt-1.5 ' : ''
          } flex rounded-6 overflow-hidden ${
            pageType === 'product' ? 'border-2' : 'border'
          } ${
            label && pageType === 'product' ? 'mt-1.5' : ''
          } transition-colors-opacity ${
            error
              ? 'border-red-60'
              : warning && pageType === 'product'
                ? 'border-yellow-500'
                : warning && pageType === 'marketing'
                  ? 'border-blue'
                  : theme === 'dark'
                    ? 'border-neutral-on-dark-25 border-opacity-60  hover:border-opacity-100 focus-within:border-blue'
                    : 'border-neutral-25-hex border-opacity-60 hover:border-opacity-100 focus-within:border-blue'
          }`}
        >
          <input
            {...rest}
            ref={ref as React.ForwardedRef<HTMLInputElement>}
            className={`  ${
              pageType === 'marketing'
                ? 'text-lg leading-none py-2.5 px-3.75'
                : 'font-medium placeholder-gray-600 text-lg sm:leading-5 py-3.5 px-4.25 focus:ring-2 ring-blue'
            } font-medium bg-white text-left rounded-6 border-none focus:outline-none transition-colors flex-1 ${
              full ? ' w-full flex-1' : ''
            } ${children ? 'rounded-r-none' : ''} ${
              className || ''
            } ${className}
            ${
              theme === 'dark'
                ? 'text-white !placeholder-neutral-on-dark-40 focus:!placeholder-neutral-on-dark-60'
                : 'text-blue placeholder-neutral-60 focus:placeholder-blue-60'
            }`}
          />
          {children}
        </div>

        {(help || error) && (
          <p
            className={`spacer-4xs ${
              pageType === 'marketing'
                ? 'text-12 leading-none pl-4 text-left'
                : 'text-sm'
            }  ${error ? 'text-red' : 'text-neutral-60'}`}
          >
            {help || error}
          </p>
        )}

        {warning && (
          <p
            className={`spacer-4xs ${
              pageType === 'marketing'
                ? `text-12 leading-none pl-4 text-left text-blue`
                : 'text-sm text-yellow-500'
            }`}
          >
            {warning}
          </p>
        )}
      </Label>
    )
  },
)

Input.displayName = 'Input'

interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  className?: string
  pageType?: 'marketing' | 'product'
}

export function Label({
  className,
  pageType = 'marketing',
  ...props
}: LabelProps): JSX.Element {
  return (
    <label
      {...props}
      className={[
        `block ${
          pageType === 'marketing'
            ? 'font-demibold small-body-text'
            : 'font-medium text-sm'
        }`,
        className,
      ]
        .filter(Boolean)
        .join(' ')}
    />
  )
}
