import React from 'react'

const PricelineLogo = (props: React.SVGAttributes<SVGElement>): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='87'
      height='22'
      viewBox='0 0 87 22'
      {...props}
    >
      <g fillRule='evenodd'>
        <g>
          <g>
            <path
              fillRule='nonzero'
              d='M78.7 10c0-.4-.1-1-.6-1.6a2.4 2.4 0 00-3.4.1c-.4.5-.5 1-.6 1.6h4.6zm3.3 4.3a9 9 0 01-1.3 1.7c-1 1-2.5 1.7-4.3 1.7-3.4 0-5.8-2.2-5.8-6 0-2.8 1.4-6.3 5.9-6.3.6 0 2.6 0 4 1.6 1.5 1.6 1.6 3.9 1.7 5.2H74c0 1.4.8 2.8 2.6 2.8 1.9 0 2.5-1.2 3-2l2.4 1.3zM62.6 6.8c.3-.4.6-.6 1.2-1 .5-.1 1.2-.3 2-.3.7 0 1.5 0 2 .4 1.3.7 1.6 1.7 1.6 3.5v7.8H66V9c-.3-.6-.9-.8-1.5-.8-1.8 0-1.8 1.4-1.8 2.8v6.2h-3.4V5.8h3.4v1zm-5.5-4c0 1-.8 1.9-1.8 1.9s-1.8-.8-1.8-1.9c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8zm-3.4 3H57v11.4h-3.3V5.7zm-2.3-5v16.4H48V.8h3.4zM43 10c0-.5-.1-1.2-.6-1.7a2.4 2.4 0 00-3.4.1c-.4.5-.5 1-.6 1.6H43zm3.4 4.2A9 9 0 0145 16c-1 1-2.5 1.7-4.3 1.7-3.4 0-5.8-2.2-5.8-6C35 9 36.4 5.4 41 5.4c.6 0 2.6 0 4 1.6 1.6 1.6 1.6 3.9 1.7 5.2h-8.2c0 1.4.8 2.8 2.6 2.8 1.9 0 2.5-1.2 3-2l2.5 1.3zm-13.2-.6l-.3.4c-.7.8-1.5.8-1.7.8-1.8 0-2.5-1.9-2.5-3.4 0-1.4.6-3.5 2.4-3.5.5 0 1 .2 1.2.4.5.3.7.7.9 1 .4-.9.8-1.8 1.5-2.5A5.5 5.5 0 0031 5.4c-3.8 0-5.7 2.9-5.7 6.2 0 4.7 3.5 6 5.7 6a5.6 5.6 0 003.7-1.4 5.3 5.3 0 01-1.4-2.5zM24 2.8c0 1-.8 1.9-1.8 1.9s-1.8-.8-1.8-1.9c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8zm-3.5 3h3.3v11.4h-3.3V5.7zm-4.7 1.1c.7-.6 1.5-1.1 3.3-1.3v3l-1.1.2c-1.6.3-2.1.5-2.1 2v6.4h-3.4V5.7h3.3V7zM5.6 8a2 2 0 00-1.7.9c-.5.6-.7 1.6-.7 2.7 0 1.5.4 2.4.8 2.9.4.4 1 .6 1.5.6C7.2 15 8 13.2 8 11.4 7.9 10 7.5 8.3 6 8h-.5zM3 6.9c0-.2.2-.3.3-.4.6-.6 1.6-1 2.9-1 2.8 0 5 2.1 5 6 0 2.3-1.1 6-5 6-1.3 0-2-.5-2.8-1v4.7H0V5.8h3v1z'
            ></path>
            <path d='M84.5 2a2.5 2.5 0 012.2 1.2c.2.4.3.8.3 1.3 0 .4-.1.9-.3 1.2a2.5 2.5 0 01-4.4 0c-.2-.3-.3-.7-.3-1.2 0-.4.1-.9.3-1.2A2.5 2.5 0 0184.5 2zm0 4.4c.3 0 .7-.1 1-.3a2 2 0 00.9-1.6 1.8 1.8 0 00-1-1.6c-.2-.2-.5-.3-.9-.3a1.8 1.8 0 00-1.6 1c-.2.2-.3.6-.3 1a1.9 1.9 0 001.9 1.8zm1-2.3v.4c-.1.2-.2.3-.4.3l.5.8H85l-.3-.7H84v.7h-.5V3.3h1c.3 0 .5 0 .7.2.2.1.3.3.3.6zm-1.4.4h.5l.3-.1v-.7H84v.7z'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PricelineLogo
