const PumaLogo = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 81 40' {...props}>
    <path
      d='M43.807.176c-.269.044-.513 1.005-1.027 1.518-.38.358-.825.334-1.072.782-.09.179-.067.445-.179.736-.2.559-.937.602-.937 1.205 0 .647.603.782 1.138 1.228.424.38.446.624.96.803.425.134 1.073-.334 1.63-.157.47.134.915.245 1.026.714.09.446 0 1.138-.58 1.049-.201-.022-1.027-.313-2.053-.201-1.227.133-2.634.535-2.79 1.92-.068.76.87 1.674 1.785 1.495.625-.133.335-.87.67-1.227.446-.47 3.036 1.629 5.425 1.629 1.004 0 1.764-.245 2.5-1.027.068-.067.157-.178.269-.2.111 0 .268.111.334.157 1.92 1.54 3.371 4.642 10.447 4.688 1.004 0 2.12.491 3.059 1.317.825.76 1.317 1.941 1.786 3.147.714 1.808 1.987 3.572 3.907 5.513.11.112 1.696 1.34 1.808 1.429.022.022.133.29.089.445-.044 1.184-.223 4.577 2.321 4.733.625.022.47-.402.47-.715 0-.602-.112-1.183.2-1.786.424-.825-.914-1.227-.87-3.035.021-1.339-1.117-1.116-1.675-2.143-.334-.602-.625-.915-.603-1.629.133-4.107-.871-6.787-1.362-7.455-.38-.491-.714-.692-.358-.937 2.12-1.406 2.612-2.701 2.612-2.701 1.138-2.658 2.142-5.068 3.548-6.139.29-.223 1.005-.76 1.45-.982 1.317-.625 1.988-.983 2.367-1.363.603-.58 1.072-1.808.492-2.544-.714-.915-1.942-.179-2.5.134-3.907 2.32-4.488 6.406-5.827 8.75-1.072 1.875-2.834 3.26-4.398 3.371-1.183.09-2.432-.157-3.705-.714-3.06-1.339-4.754-3.081-5.134-3.393-.826-.625-7.21-6.92-12.412-7.167 0 0-.647-1.295-.804-1.317-.38-.044-.76.76-1.048.871-.243.135-.69-.847-.96-.802zM20.994 37.657c-.492-.022-.893-.402-.893-.915V20.579h-5.335v17.814a1.59 1.59 0 001.585 1.585h9.309c.87 0 1.585-.714 1.585-1.585V20.579H21.91V36.74a.922.922 0 01-.916.917zm9.778-17.078c-.96 0-1.741.781-1.741 1.74v17.658h5.335V23.77c0-.491.402-.893.893-.893a.91.91 0 01.915.871v16.23h5.313v-16.23c0-.491.402-.87.893-.87s.893.4.893.892v16.207h5.335V22.321c0-.96-.782-1.74-1.74-1.74zM11.215 35.111H5.836v4.867H.5v-19.4h10.737c.96 0 1.719.782 1.719 1.741v11.028a1.744 1.744 0 01-1.74 1.764zm-3.57-11.294a.914.914 0 00-.894-.915h-.915v9.822h.915c.47 0 .916-.38.894-.915z'
      fill='currentColor'
    />
    <path
      d='M57.581 40v-4.867h-1.808V40H50.46V22.343c0-.96.782-1.74 1.74-1.74h8.997c.96 0 1.741.78 1.741 1.74V40zm0-7.276v-8.93a.896.896 0 00-.915-.87.896.896 0 00-.893.893v8.929h1.808'
      fill='currentColor'
    />
  </svg>
)

export default PumaLogo
