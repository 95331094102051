import React from 'react'

const DriveLogo = (props: React.SVGAttributes<SVGElement>): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 772.1 176.33'
      {...props}
    >
      <path d='M759.31 27.45H641.08V0L772.1.02l-12.79 27.43zM641.08 176.33h118.23l12.79-27.42-131.02-.02v27.44zM714.6 74.46l-73.52-.02v27.45h60.73l12.79-27.43zM56.52 0H0v88.16h27.45V27.45h29.07c33.48 0 60.72 27.24 60.72 60.72 0 33.48-27.24 60.72-60.72 60.72H40.25L27.46 176.3l29.06.02c48.69 0 88.16-39.47 88.16-88.16S105.2 0 56.52 0zm304.04 176.33h27.45V0h-27.45v176.33zm153.99-64.95L462.61 0h-30.28l82.22 176.33L596.77 0h-30.28l-51.94 111.38zM250.58 0h-84.19l12.8 27.45h71.39c16.77 0 30.36 13.59 30.36 30.36s-13.59 30.36-30.36 30.36H207.5l41.11 88.16h30.28l-28.31-60.71c31.87 0 57.8-25.93 57.8-57.81 0-31.88-25.93-57.81-57.8-57.81z'></path>
    </svg>
  )
}

export default DriveLogo
