import { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { XClose } from 'icons/XClose'

const Arrow = ({ ...props }: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='9'
    height='14'
    fill='none'
    viewBox='0 0 9 14'
    {...props}
  >
    <path stroke='currentColor' strokeWidth='2' d='M1 12.94l6-5.97L1 1' />
  </svg>
)

// Change this when announcing a new feature
// remember to update the show until date
const bannerConfig = {
  key: 'fuse-js-announcement',
  showUntil: '2023-12-22T22:00:00', // yyyy-mm-ddThh:mm:ss
  href: 'https://stellate.co/blog/announcing-fuse-js',
  content: (
    <>
      <span className='inline-flex items-center gap-2'>
        <span>
          🎉 Announcing Fuse.js: the opinionated framework for easily creating
          typesafe data layers
        </span>
        <Arrow className='h-3 w-auto flex-shrink-0 mt-px text-neutral-60' />
      </span>
    </>
  ),
} as const

const storageKey = `hideBanner:${bannerConfig.key}`

function getHideBannerInitialState() {
  const now = new Date()
  if (now > new Date(bannerConfig.showUntil)) {
    return true
  }
  if (typeof window !== 'undefined') {
    return window.localStorage?.getItem(storageKey) === 'true'
  } else {
    return true
  }
}

// TODO: pull banner data from Contentful?
export function FeatureAnnouncementBanner() {
  const [hideBanner, setHideBanner] = useState(true)

  // running init in effect to prevent hydration mismatch
  useEffect(() => {
    setHideBanner(getHideBannerInitialState())
  }, [])

  const hideBannerHandler = () => {
    localStorage?.setItem(storageKey, 'true')
    setHideBanner(true)
  }

  const isInternalLink = bannerConfig.href.startsWith('https://stellate.co')

  if (hideBanner) {
    return null
  }

  return (
    <div className='group relative overflow-hidden flex bg-grey pl-4 pr-12 md:pl-6 md:pr-44 lg:px-16 lg:justify-center'>
      <div className='relative flex items-center min-h-[66px] sm:min-h-[64px] w-fit-content'>
        <Link
          href={bannerConfig.href}
          target={!isInternalLink ? '_blank' : undefined}
          rel={!isInternalLink ? 'noopener noreferrer' : undefined}
          className='py-2.5 flex gap-3 md:gap-2 h-full'
          onClick={hideBannerHandler}
        >
          <div className='font-demibold flex items-center'>
            <div className='inline-block ml-1 sm:ml-2'>
              {bannerConfig.content}
            </div>
          </div>
        </Link>
        <div className='hidden absolute top-0 pointer-events-none -left-740px lg:block'>
          <Image
            priority
            src='/features/new-cache-hit-rate-tools-banner-left.svg'
            width={740}
            height={64}
            alt=''
          />
        </div>
        <div className='hidden absolute top-0 -right-740px w-740px md:block'>
          <Image
            priority
            src='/features/new-cache-hit-rate-tools-banner-right.svg'
            width={740}
            height={64}
            alt=''
          />
        </div>
      </div>

      <button
        type='button'
        className='absolute top-1/2 right-4 w-6 h-6 -translate-y-1/2 flex-shrink-0 flex items-center justify-center bg-grey rounded hover:bg-neutral-7 lg:hidden lg:group-hover:flex'
        onClick={hideBannerHandler}
      >
        <XClose aria-label='Close banner' className='w-5 h-5' />
      </button>
    </div>
  )
}
