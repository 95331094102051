import Link from 'next/link'
import cx from 'classnames'

type ButtonProps = {
  href: string
  children: React.ReactNode
  color?: 'blue' | 'red' | 'magenta' | 'gray'
  hasArrow?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export function Button({
  href,
  children,
  color = 'blue',
  hasArrow,
  onClick,
}: ButtonProps) {
  const classes = cx(
    'inline-flex items-center gap-1 h-10 px-4 rounded-8 font-demibold text-15 leading-none',
    {
      'bg-blue text-white hover:bg-blue-110': color === 'blue',
      'bg-red text-white hover:bg-red-110': color === 'red',
      'bg-magenta text-white hover:bg-magenta-110': color === 'magenta',
      'bg-gray-150 text-neutral-110 hover:bg-gray-200': color === 'gray',
    },
  )

  if (href.startsWith('/')) {
    return (
      <Link href={href} className={classes}>
        <span>{children}</span>
        {hasArrow && <Arrow className='h-6 w-6' />}
      </Link>
    )
  }

  return (
    <a
      href={href}
      target='_blank'
      rel='noreferrer'
      className={classes}
      onClick={onClick}
    >
      <span>{children}</span>
      {hasArrow && <Arrow className='h-6 w-6' />}
    </a>
  )
}

function Arrow({ ...props }: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      {...props}
    >
      <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z' />
    </svg>
  )
}
